/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import '../../css/sidebar.css';
import { logout, getAuth } from "../../Redux/userReducer"
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import axios from "axios";



function SidebarStudent(props) {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 


    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const history = useNavigate();
    const [data, setData] = useState({});

    let location = useLocation();


    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    useEffect(() => {
        if (auth.isAuth === false) {
            dispatch(getAuth());
        }
    }, [auth.isAuth, dispatch]);



    useEffect(() => {
        if (auth.user.Role === "Student") {
            axios.get(`${pathServer}/getClassbyid/` + auth.user.class)
                .then((res) => {
                    setData(res.data);
                })
                .catch((e) => {
                }
                );
        }
    }, [auth, pathServer])

    useEffect(() => {
        if (auth.user.Name === null) {
            history('/');
        }
    })

    const changelogout = () => {
        dispatch(logout());
        history("/");
        //window.location.reload(true);

        window.location.reload(false);
    };

    return (
        <>
            <div className="right-section">

                <div className="nav">
                    <div className="profile">
                        <div className="leftlogo">
                            <a href="/Absenceuser" className="logo">

                                <i className='bx bx-code-alt'></i>
                                <div className="logo-name"><span>Cla</span>ssy</div>
                            </a>
                            <div className="c">
                                {/* Toggle button */}
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={toggleSidebar}
                                    aria-controls="sidebarMenu"
                                    aria-expanded={isSidebarOpen ? "true" : "false"}
                                    aria-label="Toggle navigation"
                                    style={{
                                        paddingRight: "15px",
                                        width: "50px",
                                    }}
                                >
                                    <i className="bx bxs-dashboard"></i>
                                </button></div></div>
                        <div className="userinfo">
                            <p className="lp">Hey, <b>{auth.user.Name ? auth.user.Name : "New"}</b> <small>{data.Name ? data.Name : "New"}</small> </p>


                        </div>
                    </div>
                </div>
            </div>


            <div className="col-1">

                <nav
                    id="sidebarMenu"
                    className={`collapse d-lg-block sidebar   ${isSidebarOpen ? "show" : ""
                        }`}
                >
                    <ul className="side-menu">
                        <li className=""><a href="/"><i className='bx bxs-dashboard'></i></a></li>

                        <Link to="/student/homework">
                            <li className={location.pathname === "/student/homework" ? 'active' : ''}>
                                <a  ><i className='bx bx-analyse'></i>Homework</a>
                            </li>
                        </Link>

                        <Link to="/Absenceuser">
                            <li className={location.pathname === "/Absenceuser" ? 'active' : ''}>
                                <a  ><i className='bx bx-analyse'></i>Absence</a>
                            </li>
                        </Link>

                        <Link to="/Noteuser">
                            <li className={location.pathname === "/Noteuser" ? 'active' : ''} >
                                <a><i className='bx bx-message-square-dots'></i>Note</a>
                            </li>
                        </Link>

                        <Link to="/Supportuser">

                            <li className={location.pathname === "/Supportuser" ? 'active' : ''} >
                                <a><i className='bx bx-message-square-dots'></i>Support</a>
                            </li>
                        </Link>
                    </ul>
                    <ul className="side-menu">
                        <li>
                            <a onClick={changelogout} className="logout">
                                <i className='bx bx-log-out-circle'></i>
                                Logout
                            </a>
                        </li>
                    </ul>
                </nav >
            </div>
        </>
    );
}

export default SidebarStudent;