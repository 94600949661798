import SidebarAdmin from "../Admin/SidebarAdmin";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth } from "../../Redux/userReducer";
import axios from "axios";
import PopupAffectStudent from "../Widget/PopupAffectStudent";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';




function AbsenceStudentAdmin() {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 

   const history = useNavigate();
   const dispatch = useDispatch();
   const auth = useSelector((state) => state.auth);

   useEffect(() => {
      if (auth.isAuth === false) {
         dispatch(getAuth());
      }
   }, [auth.isAuth, dispatch]);

   useEffect(() => {
      dispatch(getAuth());
      if (auth.isAuth) {
         if (auth.user.Role === "Student") {
            history("/Absenceuser");
         }

         if (auth.user.Role === "Admin") {

         }
      }
      else {
         history("/");
      }
   }, [auth.isAuth, auth.user.Role, dispatch, history]);

   useEffect(() => {
      if (auth.user.Name === null) {
         history('/');
      }
   })

    const [isOpen, setIsOpen] = useState(false);
    const [userToaffect, setUserToaffect] = useState({});
    const [selection, setSelection] = useState("All");
    const [data, setData] = useState([{}]);
    const [name, setName] = useState("");
    const [matieres, setMatieres] = useState([{}]);
    const [matiereSelected, setMatiere] = useState("");

    const [classes, setClasses] = useState([{}]);
    const [classSelected, setClass] = useState("");


    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const MakeItCerficied = (student, id, e) => {
        e.preventDefault();
        let description = "Done";
        const databody = { description, student };

        axios.put(`${pathServer}/makeabsencecertified/${id}`, databody, {
            withCredentials: true,
        })
            .then((res) => {
                console.log(res.data);
                window.location.reload(true);
            })
            .catch((e) => {
                console.log(e);
            }
            );
    };

    useEffect(() => {
        const getUsers = (e) => {
            var path = "/getallabsencesdetails";
            axios.get(pathServer + path)
                .then(async (res) => {
                    setData(res.data);
                })
                .catch((e) => {
                }
                );
        }
        // Call the fetchData function when the component mounts
        getUsers();

        // Cleanup function (optional): This will be called when the component is unmounted
        return () => {
            // You can perform cleanup here if needed
        };
    }, [pathServer, selection]);


    useEffect(() => {
        axios.get(`${pathServer}/getallMatiere`)
            .then(async (res) => {
                setMatieres(res.data);
            })
            .catch((e) => {
            }
            );
    }, [pathServer]);

    const listMatiere = matieres.map(mat =>
        <option key={mat.id}  value={mat.Name} > {mat.Name} </option>
    );


    useEffect(() => {
        axios.get(`${pathServer}/getallClass`)
            .then(async (res) => {
                setClasses(res.data);
            })
            .catch((e) => {
            }
            );
    }, [pathServer]);



    const listClasses = classes.map(mat =>
        <option key={mat.id}  value={mat.Name} > {mat.Name} </option>
    );

    const listItems = () => {
        return data
        .filter(person => person.name && person.name.toLowerCase().includes(name.toLowerCase()))
        .filter(person => person.matiere && person.matiere.toLowerCase().includes(matiereSelected.toLowerCase()))
        .filter(person => person.classe && person.classe.toLowerCase().includes(classSelected.toLowerCase()))
    
        .map(person => (
            <tr key={person.id}> {/* Ensure each child in a list has a unique "key" prop. */}
              <td>{person.name}</td>
              <td>{person.classe}</td>
              <td>{person.date ? person.date.substring(0, 10) : " "}</td>
              <td>{person.matiere}</td>
              <td>
                {person.description ? 
                  <CheckCircleRoundedIcon style={{ color: 'green', height: '40px', width: '40px' }} /> :
                  <button onClick={(e) => MakeItCerficied(person.idsdudent, person.id , e)} className="btn btn-outline-info">Certified</button>
                }
              </td>
            </tr>
          ));
      };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-2">
                    <SidebarAdmin />
                    {isOpen && <PopupAffectStudent
                        content={<>
                            <b>Affect {userToaffect.Name} to Class</b>
                            <br />
                            <br />
                            <p>{userToaffect.Email}  </p>
                        </>}
                        user={userToaffect}
                        handleClose={togglePopup}
                    />}
                </div>
                <div className="col-10" style={{ marginTop: "100px" }}>
                    <div className="container">
                        <div className="row">

                            <div className="col-2 mb-2">
                                <label
                                    htmlFor="student"
                                    className="form-label"
                                >
                                    Student
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    id="student"
                                    name="student"
                                    placeholder="nom"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div className="col-2 mb-2">
                                <label
                                    htmlFor="aaa"
                                    className="form-label"
                                >
                                    Matiere
                                </label>
                                <select
                                    className="form-select"
                                    id="aaa"
                                    name="aaa"
                                    value={matiereSelected}
                                    onChange={(e) => setMatiere(e.target.value)}
                                >
                                    <option value="" >All</option>
                                    {listMatiere}
                                </select>
                            </div>


                            <div className="col-2 mb-2">
                                <label
                                    htmlFor="aaa"
                                    className="form-label"
                                >
                                    Classe
                                </label>
                                <select
                                    className="form-select"
                                    id="aaa"
                                    name="aaa"
                                    value={classSelected}
                                    onChange={(e) => setClass(e.target.value)}
                                >
                                    <option value="" >All</option>
                                    {listClasses}
                                </select>
                            </div>

                            
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-12">
                                <div class="recent-orders">
                                    <h2>Student</h2>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Class</th>
                                                <th>Date</th>
                                                <th>Matiere</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listItems()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default AbsenceStudentAdmin;