import SidebarAdmin from "../Admin/SidebarAdmin";
import { getAuth } from "../../Redux/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";


function NoteAbsence() {
    const pathServer = process.env.REACT_APP_API_URL
    //const pathLocal="http://localhost:4000"; 

    const history = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.isAuth === false) {
            dispatch(getAuth());
        }
    }, [auth.isAuth, dispatch]);

    useEffect(() => {
        dispatch(getAuth());
        if (auth.isAuth) {
            if (auth.user.Role === "Student") {
                history("/Absenceuser");
            }

            if (auth.user.Role === "Admin") {

            }
        }
        else {
            history("/");
        }
    }, [auth.isAuth, auth.user.Role, dispatch, history]);

    useEffect(() => {
        if (auth.user.Name === null) {
            history('/');
        }
    })

    return (
        <>
            <SidebarAdmin />
            <p> NoteAbsence </p>
        </>
    );
}


export default NoteAbsence;