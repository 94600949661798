import SidebarStudent from "../../components/Student/SidebarStudent";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';


import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth } from "../../Redux/userReducer";
import axios from "axios";
import PolarAreaChart from "../Widget/Chart/PolarAreaChart";


function AbsenceStudent() {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 

    const history = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.isAuth === false) {
            dispatch(getAuth());
        }
    }, [auth.isAuth, dispatch]);

    useEffect(() => {
        dispatch(getAuth());
        if (auth.isAuth) {
            if (auth.user.Role === "Student") {
                history("/Absenceuser");
            }

            if (auth.user.Role === "Admin") {
                history("/Useradmin");
            }
        }
        else {
            history("/");
        }
    }, [auth.isAuth, auth.user.Role, dispatch, history]);

    useEffect(() => {
        if (auth.user.Name === null) {
            history('/');
        }
    })


    const [data, setData] = useState([{}]);
    const [matieres, setMatieres] = useState([{}]);
    const [matiereSelected, setMatiere] = useState("");


    const [semesterSelected, setSemester] = useState("1");


    useEffect(() => {
        dispatch(getAuth());
    }, [dispatch])


    useEffect(() => {
        axios.get(`${pathServer}/getabsencesbystudent/` + auth.user.id)
            .then(async (res) => {
                setData(res.data);
            })
            .catch((e) => {
            }
            );
    }, [auth.user.id, pathServer])





    useEffect(() => {
        axios.get(`${pathServer}/getMatierebyclassinsemester/` + auth.user.class + "/" + semesterSelected)
            .then(async (res) => {
                setMatieres(res.data);
            })
            .catch((e) => {
            }
            );
    }, [auth.user.class, pathServer, semesterSelected]);



    const listItems = data
        .filter(absence_in => absence_in.Name && absence_in.Name.toLowerCase().includes(matiereSelected.toLowerCase()))
        .map(absence_in =>
            <tr key={absence_in.id}>
                <td>{absence_in.Name}</td>
                <td> {absence_in.Date ? absence_in.Date.substring(0, 10) : " "} </td>
                <td>{absence_in.description ? <CheckCircleRoundedIcon style={{ color: 'green', height: '40px', width: '40px' }} /> : < CancelRoundedIcon style={{ color: 'red', height: '40px', width: '40px' }} />}</td>
                <td>     </td>
            </tr>
        );


    const countOccurrences = (itemName) => {
        return data.reduce((count, item) => (item.Name === itemName ? count + 1 : count), 0);
    }

    const listItemsMatiersPercent = matieres.map(mat =>
        <tr key={mat.id}>
            <td>{mat.Name}</td>
            <td> {countOccurrences(mat.Name)} </td>
            <td style={{ backgroundColor: (countOccurrences(mat.Name) / mat.nbh) * 100 > 10 ? 'red' : 'lightblue' }}> {((countOccurrences(mat.Name) / mat.nbh) * 100).toFixed(2)}% </td>
            <td>{mat.nbh}</td>
            <td>     </td>
        </tr>
    );


    const listMatiere = matieres.map(mat =>
        <option key={mat.id} value={mat.Name} > {mat.Name} </option>
    );




    const dataStatsStudent = matieres.map(mat => countOccurrences(mat.Name));
    const d = {
        labels: matieres.map(mat => mat.Name),
        datasets: [
            {
                label: '# Absence',
                data: dataStatsStudent,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <SidebarStudent />
                    <div className="col-9 m-auto">
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-2 mb-2">
                                <label
                                    for="semester"
                                    className="form-label"
                                >
                                    Semester
                                </label>
                                <select
                                    className="form-select"
                                    id="semester"
                                    name="semester"
                                    value={semesterSelected}
                                    onChange={(e) => setSemester(e.target.value)}
                                >
                                    <option selected value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>

                            <div className="col-2 mb-2">
                                <label
                                    for="aaa"
                                    className="form-label"
                                >
                                    Matiere
                                </label>
                                <select
                                    className="form-select"
                                    id="aaa"
                                    name="aaa"
                                    value={matiereSelected}
                                    onChange={(e) => setMatiere(e.target.value)}
                                >
                                    <option value="" >All</option>
                                    {listMatiere}
                                </select>
                            </div>
                        </div>
                        <br />

                        <div className="row">
                            <div className="recent-orders">
                                <h2>Absence</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Matiere</th>
                                            <th>Date</th>
                                            <th>justifié</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listItems}
                                    </tbody>
                                </table>
                                <a href="/">...</a>
                            </div>
                        </div>
                        <br />
                        <div className="m-auto" style={{ width: '500px', height: '500px', backgroundColor: "white", borderRadius: "10px" }}>
                            <PolarAreaChart data={d} />
                        </div>
                        <br />
                        <div className="row">
                            <div className="recent-orders">
                                <h2>Absence</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Matiere</th>
                                            <th>Absence</th>
                                            <th>Pourcentage</th>
                                            <th>Nombre d'heures</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listItemsMatiersPercent}
                                    </tbody>
                                </table>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AbsenceStudent;