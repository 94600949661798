import React, { useEffect, useState } from "react";
import axios from "axios";

import SidebarAdmin from "../Admin/SidebarAdmin";
import Popup from "../Widget/Popup";


function SupportAdmin() {
    const pathServer = process.env.REACT_APP_API_URL   //const pathLocal="http://localhost:4000"; 

    const [matieres, setMatieres] = useState([{}]);
    const [matiereSelected, setMatiereSelected] = useState();


    const [sections, setSections] = useState([{}]);
    const [sectionSelected, setSectionSelected] = useState();


    const [niveau, setNiveau] = useState([{}]);
    const [niveauSelected, setNiveauSelected] = useState(14);

    const [classes, setClasses] = useState([{}]);
    const [classeSelected, setClasseSelected] = useState();

    const [namedoc, setNameDoc] = useState("");

    const [type, setType] = useState();


    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
        setIsOpen(!isOpen);
    }


    useEffect(() => {
        // get all matiers
        axios.get(`${pathServer}/getallMatiere`)
            .then(async (res) => {
                setMatieres(res.data);
            })
            .catch((e) => {
            }
            );

        // get all section 
        axios.get(`${pathServer}/getallsections`)
            .then(async (res) => {
                setSections(res.data);
            })
            .catch((e) => {
            }
            );

        // get all niveau 
        axios.get(`${pathServer}/getAllNiveau`)
            .then(async (res) => {
                setNiveau(res.data);
            })
            .catch((e) => {
            }
            );

        // get all class 
        axios.get(`${pathServer}/getallClass`)
            .then(async (res) => {
                setClasses(res.data);
            })
            .catch((e) => {
            }
            );


    }, [pathServer])

    

    const listMatiere = matieres.map(mat =>
        <option key={mat.id} value={mat.id} > {mat.Name} </option>
    );

    const listSections = sections.map(mat =>
        <option key={mat.id} value={mat.id} > {mat.Name} </option>
    );

    const listNiveaux = niveau.map(mat =>
        <option key={mat.id} value={mat.id} > {mat.Name}  </option>
    );

    const listClasses = classes.map(mat =>
        <option key={mat.id} value={mat.id} > {mat.Name} </option>
    );


    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', namedoc);
            formData.append('matiere', matiereSelected);
            formData.append('type', type);
            formData.append('section', sectionSelected);
            formData.append('niveau', niveauSelected);
            formData.append('classe', classeSelected);
            try {
                const response = await axios.post(`${pathServer}/addsupport`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('File uploaded successfully', response.data);
               togglePopup();
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            console.log('No file selected');
        }
    };


    return (
        <>
            <SidebarAdmin />
            <div className="container">
                 {isOpen && <Popup
                            content={<>
                                <b>Document ajouté avec succès </b>
                                <br />
                            </>}
                            handleClose={togglePopup}
                        />}
                <br />
                <br />
                <br />
                <div className="row">

                    <div className="col-md-6 m-auto mt-5 bg-white pb-5 pt-5 pe-5 px-5 mb-5 rounded-5">
                        <br />
                        <h1 className="mb-5"> Ajouter un document </h1>
                       
                        <div className="container">
                            <form>
                                <div className="row">                                
                                    <div className="col-6 mb-3">
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="namedoc"
                                            name="namedoc"
                                            placeholder="Nom Document"
                                            value={namedoc}
                                            onChange={(e) => setNameDoc(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-6 mb-3">
                                        <select
                                            className="form-select"
                                            id="aaa"
                                            name="aaa"
                                            value={matiereSelected}
                                            onChange={(e) => setMatiereSelected(e.target.value)}
                                        >
                                            <option value="" >Matiere</option>
                                            {listMatiere}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <select
                                            className="form-select"
                                            id="bbb"
                                            name="bbb"
                                            value={sectionSelected}
                                            onChange={(e) => setSectionSelected(e.target.value)}
                                        >
                                            <option value="" >Section</option>
                                            {listSections}
                                        </select>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <select
                                            className="form-select"
                                            id="bbb"
                                            name="bbb"
                                            value={classeSelected}
                                            onChange={(e) => setClasseSelected(e.target.value)}
                                        >
                                            <option value="" >Class</option>
                                            {listClasses}
                                        </select>
                                    </div>
                                    {/* <div className="col-6 mb-3">
                                        <select
                                            className="form-select"
                                            id="cccc"
                                            name="ccc"
                                            value={niveauSelected}
                                            onChange={(e) => setNiveauSelected(e.target.value)}
                                        >
                                            <option value="" ></option>
                                            {listNiveaux}
                                        </select>
                                    </div> */}

                                </div>
                                <div className="row">

                                    <div className="col-6 mb-3">
                                        <select
                                            className="form-select"
                                            id="bbb"
                                            name="bbb"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            <option value="" >Type</option>
                                            <option value="Cours" >Cours</option>
                                            <option value="Exam" >Exam</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">

                                    <label for="file-upload" className="file-upload-label">
                                    Choisir un fichier
                                    </label>
                                    <input type="file" id="file-upload" class="file-upload-input" onChange={handleFileChange} />

                                </div>
                                <br />
                                <div className="Search__actions">
                                    <button onClick={handleSubmit} className="btn btn-outline-primary btn-custom rounded-5">
                                        Ajouter
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SupportAdmin;