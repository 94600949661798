import SidebarAdmin from "./SidebarAdmin";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { DoughnutChart } from "../Widget/Chart/DoughnutChart";
import PopupStudentAbsenceInMatiere from "../Widget/PopupStudentAbsenceInMat";
import { getAuth } from "../../Redux/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";





function AbsenceClassStats() {
    const pathServer = process.env.REACT_APP_API_URL
    //const pathLocal="http://localhost:4000"; 

    const history = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.isAuth === false) {
            dispatch(getAuth());
        }
    }, [auth.isAuth, dispatch]);

    useEffect(() => {
        dispatch(getAuth());
        if (auth.isAuth) {
            if (auth.user.Role === "Student") {
                history("/Absenceuser");
            }

            if (auth.user.Role === "Admin") {
                
            }
        }
        else {
            history("/");
        }
    }, [auth.isAuth, auth.user.Role, dispatch, history]);

    useEffect(() => {
        if (auth.user.Name === null) {
            history('/');
        }
    })


    const [classe, setMainData] = useState({});
    const [semester, setSemester] = useState([]);
    const [student, setStudent] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const [matselected, setMat] = useState("");




    const togglePopup = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setMat("");
        }
    }


    const { classId } = useParams();

    useEffect(() => {
        axios.get(`${pathServer}/getstatsforclasse/${classId}`)
            .then(async (res) => {
                setMainData(res.data);
                //console.log()
                setSemester(res.data.Matiere);
            })
            .catch((e) => {
            }
            );
    }, [classId, pathServer])

    useEffect(() => {
        axios.get(`${pathServer}/getabsenceofstudentinspecificclass/${classId}`)
            .then(async (res) => {
                setStudent(res.data);
            })
            .catch((e) => {
            }
            );
    }, [classId, pathServer])


    const listItems = student.map(person =>
        <tr key={person.id} >
            <td>{person.Name}</td>
            <td>{person.Email}</td>
            <td>{person.semester1}</td>
            <td>{person.semester2}</td>
        </tr>
    );


    const listMatiere = semester.map(mat => {
        let numberSemester = mat.semestre;
        //let numberAbsenceInsemester = mat.nbhAbsentSemester;

        const listdetails = mat.matieres.map(m => {
            let numberAbsenceInMatiere = m.nbhAbsent;
            let nbStudentDistinct = m.nbStudentDistinct;
            let matiereName = m.Name;
            //detailss
            const dataSpecific = {
                labels: ["Student Absent", "Student Present"],//mat.matieres.map(m => m.Name),
                datasets: [
                    {
                        label: '# ',
                        data: [nbStudentDistinct, student.length - nbStudentDistinct], // mat.matieres.map(m => m.nbhAbsent),
                        backgroundColor: [
                            // 'rgba(255, 99, 132, 0.2)',
                            // 'rgba(54, 162, 235, 0.2)',
                            //'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            // 'rgba(255, 99, 132, 1)',
                            // 'rgba(54, 162, 235, 1)',
                            //'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            };

            return (
                <div key={m.id} className="col-4" onClick={() => { togglePopup(); setMat(m.id) }} style={{ width: "350px", backgroundColor: "white", borderRadius: "12px", margin: "10px" }} >
                    <p>{matiereName}</p>
                    <div >
                        <DoughnutChart data={dataSpecific} />
                    </div>
                    <br />
                    <p>Total Absent <b> {numberAbsenceInMatiere} </b></p>
                    <br />
                    <br />
                </div>
            )
        });



        const data = {
            labels: mat.matieres.map(m => m.Name),
            datasets: [
                {
                    label: '# ',
                    data: mat.matieres.map(m => m.nbhAbsent),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        return (
            <div className="row" >
                <strong> <p style={{ paddingRight: '80%' }} >Semester {numberSemester}</p></strong>
                <div style={{ width: '400px', height: "500px", backgroundColor: "white", borderRadius: "12px", margin: "10px" }}>
                    <DoughnutChart data={data} />
                </div>
                <br />
                <br />
                {listdetails}
                <br />
            </div>

        )
    }
    );

    return (
        <>
            <SidebarAdmin />
            <br /><br /><br />
            <h1>{classe.Name}</h1>
            <div className="container">

                <div className="row">
                    <div className="col-2">
                        {isOpen && <PopupStudentAbsenceInMatiere
                            content={<>
                                <b>{classe.Name}</b>
                                <br />
                                <br />
                                <p></p>
                            </>}
                            handleClose={togglePopup}
                            idclass={classe.id}
                            idmat={matselected}
                        />}
                    </div>
                    <div className="col-10" style={{ marginTop: "0px" }}>
                        <div className="container">
                            <br />
                            <div className="row">
                                <div className="col-12">
                                    <div class="recent-orders">
                                        <h2>Users</h2>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Semester 1</th>
                                                    <th>Semester 2</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listItems}
                                            </tbody>
                                        </table>
                                        <a href="/">...</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <br />

                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-11">
                        {listMatiere}
                    </div>
                </div>
            </div>



        </>
    );
}

export default AbsenceClassStats;