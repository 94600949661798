import React, { useEffect, useState } from "react";
import '../../css/sidebar.css';
import axios from "axios";


const HomeworkForm = ({ homework, handleChange, handleSubmit, idProf }) => {
  const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 

  const [showPopup, setShowPopup] = useState(false);
  const [classes, setClasses] = useState([{}]);
  const [matieres, setMatieres] = useState([{}]);


  useEffect(() => {
    axios.get(`${pathServer}/matieresProfForProf/${idProf}`)
      .then(async (res) => {
        setMatieres(res.data);
      })
      .catch((e) => {
      }
      );
  }, [idProf, pathServer]);


  useEffect(() => {
    axios.get(`${pathServer}/classProfs/` + idProf)
      .then((res) => {
        setClasses(res.data);
      })
      .catch((e) => {
      }
      );

  },)


  const listeSidebarProdClasses = classes.map(c =>
    <option value={c.id_class}>{c.Name}</option>
  );


  const listeSidebarProdMatiere = matieres.map(c =>
    <option value={c.id_matiere}>{c.Name}</option>
  );


  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 3000); // Hide popup after 3 seconds
  };

  return (
    <div className="homework-form-container">
      <form onSubmit={onSubmit}>
        {/* Card 1: Basic Information */}
        <div className="form-card">
          <h3>Basic Information</h3>
          <div className="form-group">
            <label>Matière:</label>
            <select name="id_matiere" value={homework.id_matiere} onChange={handleChange}>
              <option value="">Select Matière</option>
              {listeSidebarProdMatiere}
            </select>
          </div>
          <div className="form-group">
            <label>Class </label>
            <select name="id_class" value={homework.id_class} onChange={handleChange}>
              <option value="">Select Class</option>
              {listeSidebarProdClasses}
            </select>
          </div>
        </div>

        {/* Card 2: Homework Details */}
        <div className="form-card">
          <h3>Homework Details</h3>
          <div className="form-group">
            <label>Deadline:</label>
            <input
              type="datetime-local"
              name="deadline"
              value={homework.deadline}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <textarea
              name="description"
              value={homework.description}
              onChange={handleChange}
              placeholder="Enter homework description"
            />
          </div>
          <div className="form-group">
            <label>Link:</label>
            <input
              type="text"
              name="link"
              value={homework.link}
              onChange={handleChange}
              placeholder="Enter resource link"
            />
          </div>
        </div>

        <button type="submit" className="submit-button">Add Homework</button>
      </form>

      {showPopup && (
        <div className="popup">
          <p>Homework added successfully!</p>
        </div>
      )}
    </div>
  );
};

export default HomeworkForm;