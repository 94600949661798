import React, { useState, useEffect } from 'react'; // Import useEffect for dynamic data fetching
import SidebarStudent from './SidebarStudent';

const StudentPageHomework = () => {
  // Static data for homework assignments
  const staticHomeworkAssignments = [
    {
      id: 1,
      matiere: 'Math',
      prof: 'Dr. Smith',
      timePosted: '2023-10-01T10:00:00Z',
      deadline: '2023-10-10T23:59:00Z',
      description: 'Complete exercises 1 to 5 from Chapter 3.',
      link: 'https://example.com/math-homework',
      classid: 'ClassA',
    },
    {
      id: 2,
      matiere: 'Science',
      prof: 'Dr. Johnson',
      timePosted: '2023-10-02T09:00:00Z',
      deadline: '2023-10-12T23:59:00Z',
      description: 'Write a report on the solar system.',
      link: 'https://example.com/science-homework',
      classid: 'ClassA',
    },
    {
      id: 3,
      matiere: 'History',
      prof: 'Dr. Brown',
      timePosted: '2023-10-03T11:00:00Z',
      deadline: '2023-10-15T23:59:00Z',
      description: 'Research and present on World War II.',
      link: 'https://example.com/history-homework',
      classid: 'ClassA',
    },
  ];

  // State for dynamic data (commented out for now)
  const [homeworkAssignments, setHomeworkAssignments] = useState(staticHomeworkAssignments);

  // Uncomment this block when your backend is ready
  /*
  useEffect(() => {
    const fetchHomework = async () => {
      try {
        const response = await fetch('/api/homework?classid=ClassA'); // Replace with your API endpoint
        const data = await response.json();
        setHomeworkAssignments(data);
      } catch (error) {
        console.error('Error fetching homework:', error);
      }
    };
    fetchHomework();
  }, []);
  */

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2">
          <SidebarStudent />
        </div>
        <div className="col-9" style={{ marginTop: "100px" }}>
          <br />
          <div className="row">
            <div className="col-12">
              <div className="student-page">
                <h1>Homework Assignments</h1>
                <div className="homework-list">
                  {homeworkAssignments.length > 0 ? (
                    homeworkAssignments.map((hw) => (
                      <div key={hw.id} className="homework-item">
                        <h3>{hw.matiere}</h3>
                        <p><strong>Professor:</strong> {hw.prof}</p>
                        <p><strong>Posted:</strong> {new Date(hw.timePosted).toLocaleString()}</p>
                        <p><strong>Deadline:</strong> {new Date(hw.deadline).toLocaleString()}</p>
                        <p><strong>Description:</strong> {hw.description}</p>
                        <p>
                          <strong>Link:</strong>{' '}
                          <a href={hw.link} target="_blank" rel="noopener noreferrer">
                            {hw.link}
                          </a>
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No homework assigned.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default StudentPageHomework;