import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import './js/pagecss.js';
import SignIn from "./components/SignIn";
import AbsenceStudent from "./components/Student/AbsenceStudent";
import NoteStudent from "./components/Student/NoteStudent";
import SupportStudent from "./components/Student/SupportStudent";

import AbsenceAdmin from "./components/Admin/AbsenceStatsAdmin.js";
import NoteAdmin from "./components/Admin/NoteAbsence";
import SupportAdmin from "./components/Admin/SupportAdmin";
import UserAdmin from "./components/Admin/UserAdmin";
import AddUser from './components/Admin/AddUser.js'
import AbsenceClassStats from "./components/Admin/AbsenceClassStats.js";
import ForgetPassword from "./components/ForgetPassword.js";
import AbsenceStudentAdmin from "./components/Admin/AbsenceStudentAdmin.js";
import ClassesProf from "./components/Prof/ClassesProf.js";
import RestPassword from "./components/Student/RestPassword.js";
import ProfessorPageHomework from "./components/Prof/ProfessorPageHomework.js";
import StudentPageHomework from "./components/Student/StudentPageHomework.js";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Routes>
                <Route exact path="/" element={<SignIn />} />
                <Route path="/Absenceuser" element={<AbsenceStudent />} />
                <Route path="/student/homework" element={<StudentPageHomework />} />
                <Route path="/Noteuser" element={<NoteStudent />} />
                <Route path="/Supportuser" element={<SupportStudent />} />
                <Route path="/Useradmin" element={<UserAdmin />} />
                <Route path="/Absencestatsadmin" element={<AbsenceAdmin />} />
                <Route path="/Absencesadmin" element={<AbsenceStudentAdmin />} />
                <Route path="/Noteadmin" element={<NoteAdmin />} />
                <Route path="/Supportadmin" element={<SupportAdmin />} />
                <Route path="/AddUseradmin" element={<AddUser />} />
                <Route path="/Absenceadmin/statsClass/:classId" element={<AbsenceClassStats />} />
                <Route path="/StudentRestPassword/:token" element={<RestPassword />} />
                <Route path="/AbsenceClassProf/:classId/:className" element={<ClassesProf />} />
                <Route path="/ProfHomework" element={<ProfessorPageHomework />} />
                <Route path="/ResetPassword/:token" element={<ForgetPassword />} />

                <Route exact path="*" element={<SignIn />} />

              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
