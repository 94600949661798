/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import '../../css/sidebar.css';
import { logout, getAuth } from "../../Redux/userReducer"
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import axios from "axios";



function SidebarProf(props) {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 


    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const history = useNavigate();
    const [classes, setClasses] = useState([{}]);


    let location = useLocation();


    useEffect(() => {
        if (auth.isAuth === false) {
            dispatch(getAuth());
        }
    }, [auth.isAuth, dispatch]);



    useEffect(() => {
        if (auth.user.Name === null) {
            history('/');
        }
    })

    useEffect(() => {
        if (auth.user.Role === "Prof") {
            axios.get(`${pathServer}/classProfs/` + auth.user.id)
                .then((res) => {
                    setClasses(res.data);
                })
                .catch((e) => {
                }
                );
        }
    }, [auth, pathServer])

    const listeSidebarProdClasses = classes.map(c =>
        <Link to={`/AbsenceClassProf/${c.id_class}/${c.Name}`}>
            <li className={location.pathname === `/AbsenceClassProf/${c.id_class}/${c.Name}` ? 'active' : ''}>
                <a><i className='bx bx-analyse'></i>{c.Name}</a>
            </li>
        </Link>
    );


    const changelogout = () => {
        dispatch(logout());
        history("/");
        window.location.reload(false);
    };

    return (
        <>
            <div className="right-section">
                <div className="nav">
                    <div className="profile">
                        <p>Hey, <b>{auth.user.Name}</b></p>
                    </div>
                </div>
            </div>
            <div className="col-1">
                <div className="sidebar">
                    <a href="#" className="logo">
                        <i className='bx bx-code-alt'></i>
                        <div className="logo-name"><span>Cla</span>ssy</div>
                    </a>
                    <ul className="side-menu">
                        <li className=""><a href="#"><i className='bx bxs-dashboard'></i></a></li>

                        {listeSidebarProdClasses}

                        <Link to={`/ProfHomework`}>
                            <li className={location.pathname === `/ProfHomework` ? 'active' : ''}>
                                <a><i className='bx bx-analyse'></i>Homework</a>
                            </li>
                        </Link>
                    </ul>
                    <ul className="side-menu">
                        <li>
                            <a onClick={changelogout} className="logout">
                                <i className='bx bx-log-out-circle'></i>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default SidebarProf;