import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import HomeworkForm from './HomeworkForm';
import SidebarProf from './SidebarProf';
import { getAuth } from "../../Redux/userReducer"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";


const ProfessorPageHomework = () => {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 

    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const history = useNavigate();


    useEffect(() => {
        if (auth.isAuth === false) {
            dispatch(getAuth());
        }
    }, [auth.isAuth, dispatch]);


    useEffect(() => {
        if (auth.user.Name === null) {
            history('/');
        }
    })

    const [homework, setHomework] = useState({
        id: '',
        id_matiere: '',
        id_prof: auth.user.id,
        deadline: '',
        description: '',
        link: '',
        id_class: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.post(`${pathServer}/addHomework`, homework, {
            withCredentials: true,
        })
            .then((res) => {
                if (res.status === 201) {
                    setHomework({
                        id: '',
                        id_matiere: '',
                        id_prof: auth.user.id,
                        deadline: '',
                        description: '',
                        link: '',
                        id_class: '',
                    });
                }
            })
            .catch((e) => {
                // UX go to users to affect if its students in a class 
            });

    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setHomework({ ...homework, [name]: value });
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-2">
                    <SidebarProf />
                </div>
                <div className="col-9" style={{ marginTop: "100px" }}>
                    <br />
                    <div className="row">
                        <div className="col-12">
                            <div className="professor-page">
                                <h1>Add Homework</h1>
                                <HomeworkForm
                                    homework={homework}
                                    handleChange={handleChange}
                                    handleSubmit={handleSubmit}
                                    idProf={auth.user.id}
                                />
                            </div>
                        </div>
                    </div>
                    <br />

                </div>
            </div>

        </div>
    );
};

export default ProfessorPageHomework;




